<template>
    <div class="layer">
        <div class="layerCatalog  box-shadow" v-loading="catalogLoading">
            <div class="layerCatalogTool">
                <div>图层目录</div>
                <div>
                    <i class="item iconfont el-icon-download" @click="exportLayer" :class="{'loading':exportLayerLoading}" title="导出"></i>
                    <SelectFile :change="importLayerChange" accept="application/json" class="item">
                        <i slot="button" class="iconfont el-icon-upload2" @click="importLayer($event)" :class="{'loading':importLayerLoading}" title="导入"></i>
                    </SelectFile>
                    <i class="item iconfont iconadd" @click="addCatalog" title="新增目录"></i>
                    <i class="item iconfont iconascending" @click="saveCatalogSort" title="保存排序"></i>
                </div>
            </div>
            <el-input class="filterCatalogText" v-model="filterCatalogText" placeholder="请输入目录名称查询" size="small"></el-input>
            <el-scrollbar class="sidebar-wrapper">
                <el-tree ref="catalogTree" :filter-node-method="filterNode" :data="catalogTreeData" default-expand-all :expand-on-click-node="false" :draggable="true" show-checkbox :allow-drop="allowDrop">
                    <div class="custom-tree-node" :class="{'active':parameter.layerCatalogId==data.id}" slot-scope="{ node, data }">
                        <span class="name" @click="catalogNameClick(data)">{{  data.catalogName  }}</span>
                        <div class="control">
                            <i class="iconfont iconbianji" @click="editCatalog(data)"></i>
                            <i class="iconfont icondelete1" @click="delCatalog(data)"></i>
                        </div>
                    </div>
                </el-tree>
            </el-scrollbar>
        </div>
        <div class="mainList">
            <div class="mainList_content">
                <div class="mainList_content_operation">

                    <div class="mainList_content_operation-l">
                        <el-button size="small" icon="el-icon-plus" @click="add()">新增</el-button>
                        <el-button size="small" icon="el-icon-sort" type="primary" @click="saveSort" :loading="saveSortLoading">保存排序</el-button>
                        <el-button type="danger" icon="el-icon-delete"  size="small" @click="del()">批量删除</el-button>
                    </div>

                    <div class="mainList_content_operation-c">
                        <div class="item">
                            <div class="label">图层名称</div>
                            <el-input placeholder="请输入" size="small" clearable v-model="parameter.layerName" />
                        </div>
                        <el-button class="item" type="primary" size="small" icon="el-icon-search" @click="pageChangeHandler(1)">搜索</el-button>
                    </div>

                    <div class="mainList_content_operation-r">
                        <div class="item icon iconfont iconrefresh" @click="getData"></div>
                        <el-popover class="item" placement="bottom" v-model="tableSettingVisible" popper-class="tableColumnSettingPopover">
                            <TableColumnSetting v-model="tableColumnData"></TableColumnSetting>
                            <div slot="reference" class="item icon iconfont iconliebiaoshezhi"></div>
                        </el-popover>
                    </div>

                </div>
                <div class="mainList_content_sub">
                    <el-table ref="multipleTable" row-key="id" :stripe="elTable.stripe" :border="elTable.border" :data="tableData" tooltip-effect="dark" height="string" @sort-change="sortChange" v-loading="loading" header-cell-class-name="meterHeader">
                        <el-table-column type="selection" align="center" width="55" fixed="left"></el-table-column>
                        <el-table-column label="序号" type="index" align="center" width="70"></el-table-column>

                        <el-table-column v-for="n in tableColumnData" v-if="n.isShow" :fixed="n.fixed" align="center" :prop="n.prop" :label="n.label" :sortable="n.sortable">
                            <template slot-scope="scope">
                                <template v-if="n.filter">
                                    {{  n.filter(scope.row[n.prop])  }}
                                </template>
                                <template v-else>
                                    {{  scope.row[n.prop]  }}
                                </template>
                            </template>
                        </el-table-column>

                        <el-table-column label="操作" fixed="right"  align="center" width="150">
                            <template slot-scope="scope">
                                <el-button type="text" class="edit" @click="edit(scope.row)">编辑</el-button>
                                <el-button class="del" type="text" @click="del(scope)">删除</el-button>
                            </template>
                        </el-table-column>

                    </el-table>
                </div>
            </div>
        </div>
        <CatalogEdit :refresh="refresh" :isShow.sync="catalogShowEditDialog" :options="catalogOptions" :listData="catalogData"></CatalogEdit>
        <Edit :refresh="refresh" :isShow.sync="showEditDialog" :options="options" :listData="catalogData"></Edit>
    </div>
</template>
<script>
import mixinsList from '@/mixins/list';
import {initTreeData, treeToTile, getUUID, download,fileParsing} from "@/util/common";
import Sortable from "sortablejs";
import Axios from "@/axios";
export default {
    components: {
        CatalogEdit: () => import("./CatalogEdit.vue"),
        Edit: () => import("./Edit.vue"),
        TableColumnSetting: () => import("@/components/TableColumnSetting.vue"),
        SelectFile: () => import("@/components/upload/SelectFile.vue"),
    },
    mixins: [mixinsList],
    data() {
        return {
            api:{
                findPage:this.$api['admin/layer'].findList,
                del:this.$api['admin/layer'].del,
            },
            tableColumnData:[
                {prop:'layerName',label:'图层名称',isShow:true,sortable:false,fixed:false,},
                {prop:'layerType',label:'图层类型',isShow:true,sortable:false,fixed:false,},
            ],
            saveSortLoading:false,
            catalogShowEditDialog:false,
            catalogOptions:{},
            catalogData:[],
            catalogTreeData:[],
            catalogLoading:true,
            filterCatalogText:'',
            exportLayerLoading:false,
            importLayerLoading:false,
        };
    },
    watch:{
        filterCatalogText(val){
            this.$refs.catalogTree.filter(val);
        }
    },
    created() {
        this.getData();
        this.getCatalogData();
    },
    mounted(){
        this.elFormTable = this.$refs.multipleTable.$el.querySelector('.el-table__body-wrapper tbody');
        new Sortable(this.elFormTable, {
            animation: 150,
            ghostClass: '.el-table__row',
            onMove:r=>{
                return true;
            },
            onEnd:r=> {
                var currRow = this.tableData.splice(r.oldIndex, 1)[0];
                this.tableData.splice(r.newIndex, 0, currRow);
            }
        });
    },
    methods: {
        exportLayer(){

            if(this.exportLayerLoading){return false};
            var catalogArr = this.$refs.catalogTree.getCheckedNodes();
            if(catalogArr.length<1){
                this.$message.error("请选择目录数据！");
                return false
            }
            this.exportLayerLoading = true;
            this.$api['admin/layer'].findList().then(res=>{
                var layerData = res.data;
                var arr = [];
                catalogArr.some(item=>{
                    var layers = [];
                    layerData.some(n=>{
                        if(n.layerCatalogId==item.id){
                            layers.push(n)
                        }
                    })
                    arr.push(item.combination({
                        layers,
                    }))
                });
                var blob = new Blob([JSON.stringify({layerCatalogs:arr})]);
                download(blob,'图层.json');
                this.exportLayerLoading = false;
            }).catch(err => {
                console.log(err);
                this.exportLayerLoading = false;
            });
        },
        importLayerChange(res){
            var that = this;
            this.importLayerLoading = true;
            fileParsing(res.files[0]).then(res=>{
                return JSON.parse(res);
            }).then(cond=>{
                this.$api['admin/layerCatalog'].batchSave(cond).then(res => {
                    this.$message.success("操作成功！");
                    this.importLayerLoading = false;
                    this.getData();
                    this.getCatalogData();
                }).catch(err => {
                    console.log(err);
                    that.importLayerLoading = false;
                });
            }).catch(err => {
                this.$message.error(`文件解析错误！${err}`);
                this.importLayerLoading = false;
            });
        },
        importLayer(e){
            if(this.importLayerLoading){
                e.stopPropagation();
            };
        },
        allowDrop(draggingNode, dropNode, type) {
            //注掉的是同级拖拽
            if (draggingNode.level === dropNode.level) {
                return type === 'prev' || type === 'next'
            } else {
                // 不同级进行处理
                return false
            }
        },
        saveSort(){
            var sortVos = [];

            for(let i=0;i<this.tableData.length;i++){
                let r = this.tableData[i]
                sortVos.push({
                    id:r.id,
                    sort:i,
                })
            };

            this.saveSortLoading = true;

            this.$api['admin/layer'].batchSort({sortVos}).then( res => {
                this.saveSortLoading = false;
                this.$message.success("操作成功！");
            }).catch(err=>{
                this.saveSortLoading = false;
                console.log(err);
            })
        },
        saveCatalogSort(){
            var sortVos = [];
            var arr = treeToTile(this.catalogTreeData);
            for(let i=0;i<arr.length;i++){
                let r = arr[i]
                sortVos.push({
                    id:r.id,
                    sort:i,
                })
            };
            this.$api['admin/layerCatalog'].batchSort({sortVos}).then( res => {
                this.$message.success("操作成功！");
            }).catch(err=>{
                console.log(err);
            })
        },
        add() {
            this.options = {
                layerCatalogId:this.parameter.layerCatalogId||'0',
                legendFile:[],
            };
            this.showEditDialog = true;
        },
        edit(row) {
            var options = row.deepClone();
            try {
                options.legendFile = JSON.parse(row.legendFile);
            }catch (err){
                options.legendFile = [];
                console.log(err);
            };
            this.options = options;
            this.showEditDialog = true;
        },
        catalogNameClick(data){
            this.$set(this.parameter,'layerCatalogId', data.id);
            this.getData();
        },
        refresh(){
            if(this.catalogShowEditDialog){
                this.catalogShowEditDialog = false;
                this.getCatalogData();
            }else {
                this.showEditDialog = false;
                this.getData();
            }
        },
        addCatalog(){
            this.catalogOptions = {
                parentId:'0',
            };
            this.catalogShowEditDialog = true;
        },
        delCatalog(row){
            this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                this.$api['admin/layerCatalog'].del({ids:[row.id]}).then(res => {

                    this.$message.success("删除成功！");
                    this.getCatalogData();

                }).catch(err => {
                    console.log(err);
                });
            });
        },
        editCatalog(row){
            this.catalogOptions = row;
            this.catalogShowEditDialog = true;
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.catalogName.indexOf(value) !== -1;
        },
        getCatalogData(){
            this.catalogLoading = true;
            this.$api['admin/layerCatalog'].findList().then(res=>{
                this.catalogData = res.data;
                this.catalogTreeData = initTreeData(res.data);
                this.catalogLoading = false;
            }).catch(err => {
                console.log(err);
                this.catalogLoading = false;
            });
        },
        getData(){
            this.loading = true;
            this.$api['admin/layer'].findList(this.parameter).then(res=>{
                this.loading = false;
                this.tableData = res.data;
            }).catch(err => {
                console.log(err);
                this.loading = false;
            });
        },
    },
};
</script>
<style scoped lang='scss'>
@import "@/styles/config.scss";
.layer {
    padding-left:320px;
    .layerCatalog {
        position:absolute;
        top:20px;
        bottom:20px;
        left:20px;
        width:300px;
        background: #fff;
        padding: 15px;
        border-radius: 5px;
        overflow:auto;
        font-size:14px;
        .layerCatalogTool {
            display:flex;
            justify-content:space-between;
            height:47px;
            align-items:center;
            padding-bottom:10px;
            border-bottom:#F0F0F0 solid 1px;
            font-size:16px;
            .iconfont {
                font-size:24px;
                color:$themeColor;
                cursor:pointer;
                &.loading {
                    cursor:progress;
                }
            }
            .item {
                +.item {margin-left:10px;}
            }
        }
        .filterCatalogText {
            margin:10px 0;
        }
        .sidebar-wrapper {
            height:calc(100% - 99px);
            /deep/.el-scrollbar__wrap {
                overflow-x: hidden;
            }
            /deep/.el-tree {
                .el-tree-node__content {
                    background:none;
                    cursor:auto;
                    &:hover {
                        color:$themeColor;
                        .custom-tree-node {
                            .control {display:flex;}
                        }
                    }
                }
                .custom-tree-node {
                    position:relative;
                    width:100%;
                    &.active {
                        color:$themeColor;
                    }
                    .name {cursor:pointer;}
                    .control {
                        position:absolute;
                        top:0;
                        right:0;
                        bottom:0;
                        display:none;
                        justify-content:space-between;
                        align-items:center;
                        .iconfont {
                            font-size:16px;
                            cursor:pointer;
                            +.iconfont {
                                margin-left:5px;
                            }
                            &.iconbianji {color:$themeColor;}
                            &.icondelete1 {color:$danger;}
                        }
                    }
                }
            }
        }
    }
}
</style>
